<template>
  <div class="row">
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 1</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">Chart / 2</h2>
        <div class="card">
          <kit-chart-2 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 9</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-9 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">Chart / 5</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-5 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 10</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-10 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">Chart / 6</h2>
        <div class="card">
          <kit-chart-6 />
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 3</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-3 />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">Chart / 4</h2>
            <div class="card">
              <div class="card-body">
                <kit-chart-4 />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">Chart / 4-1</h2>
            <div class="card">
              <div class="card-body">
                <kit-chart-4v1 />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">Chart / 4-2</h2>
            <div class="card">
              <div class="card-body">
                <kit-chart-4v2 />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <h2 class="badge-example">Chart / 4-3</h2>
            <div class="card">
              <div class="card-body">
                <kit-chart-4v3 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 7</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-7 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">Chart / 8</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-8 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 11</h2>
        <div class="card">
          <kit-chart-11 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 11-1</h2>
        <div class="card">
          <kit-chart-11v1 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 11-2</h2>
        <div class="card">
          <kit-chart-11v2 />
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 12</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-12 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 12-1</h2>
        <div class="card">
          <div class="card-body">
            <kit-chart-12v1 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 13</h2>
        <div class="card">
          <kit-chart-13 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 13-1</h2>
        <div class="card">
          <kit-chart-13v1 />
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">Chart / 13-2</h2>
        <div class="card">
          <kit-chart-13v2 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KitChart1 from '@/components/kit/widgets/Charts/1'
import KitChart2 from '@/components/kit/widgets/Charts/2'
import KitChart3 from '@/components/kit/widgets/Charts/3'
import KitChart4 from '@/components/kit/widgets/Charts/4'
import KitChart4v1 from '@/components/kit/widgets/Charts/4v1'
import KitChart4v2 from '@/components/kit/widgets/Charts/4v2'
import KitChart4v3 from '@/components/kit/widgets/Charts/4v3'
import KitChart5 from '@/components/kit/widgets/Charts/5'
import KitChart6 from '@/components/kit/widgets/Charts/6'
import KitChart7 from '@/components/kit/widgets/Charts/7'
import KitChart8 from '@/components/kit/widgets/Charts/8'
import KitChart9 from '@/components/kit/widgets/Charts/9'
import KitChart10 from '@/components/kit/widgets/Charts/10'
import KitChart11 from '@/components/kit/widgets/Charts/11'
import KitChart11v1 from '@/components/kit/widgets/Charts/11v1'
import KitChart11v2 from '@/components/kit/widgets/Charts/11v2'
import KitChart12 from '@/components/kit/widgets/Charts/12'
import KitChart12v1 from '@/components/kit/widgets/Charts/12v1'
import KitChart13 from '@/components/kit/widgets/Charts/13'
import KitChart13v1 from '@/components/kit/widgets/Charts/13v1'
import KitChart13v2 from '@/components/kit/widgets/Charts/13v2'

export default {
  components: {
    KitChart1,
    KitChart2,
    KitChart3,
    KitChart4,
    KitChart4v1,
    KitChart4v2,
    KitChart4v3,
    KitChart5,
    KitChart6,
    KitChart7,
    KitChart8,
    KitChart9,
    KitChart10,
    KitChart11,
    KitChart11v1,
    KitChart11v2,
    KitChart12,
    KitChart12v1,
    KitChart13,
    KitChart13v1,
    KitChart13v2,
  },
}
</script>
